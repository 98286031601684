@import './root.css';
@import './borders.css';
@import './btn.css';
@import './carousel.css';
@import './queries.css';
@import './grids.css';
@import './bg-images.css';
@import './fonts-size.css';
@import './modal.css';

/* HEADERS */
h1, h2, h3, h4, h5 {

    font-weight: bolder;

}

/* LISTS */
ul li {

    margin-bottom: 1rem;

}

/* GRADIENT */
.gray-gradient {

    background-color: rgb(214, 211, 214);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(214, 211, 214, 1) 100%);
    background-repeat: no-repeat;
    background-size: contain;

}

/* WHITE */
.bg-white, .bg-white a, .bg-white input::placeholder, .bg-white svg, .nav-white a, .nav-white input::placeholder, .nav-white svg {

    color: black !important;

}

/* RED */
.border-red {

    border-color: var(--red);

}
.border-bottom-red {

    border-bottom: 1px solid;
    border-color: var(--red);

}
.border-2-top-red {

    border-top: 25px solid;
    border-color: var(--red);

}

/* BLUE */
.bg-blue {

    background-color: var(--blue);
    color: white;

}
.text-blue {

    color: var(--blue);

}

.border-blue {

  border-color: var(--blue);

}

/* FAQ */
.faqs {

  list-style: disc !important;
  padding-left: 1rem;
  text-align: justify;

}
.collapsible {

  list-style-type: none;
  margin: 0;

}
.collapsible li {

  margin-bottom: 10px;

}
.collapsible-header {

  display: block;
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  text-align: justify;

}
.collapsible-body {

  visibility: hidden;
  height: 0px;
  padding: 10px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 15px;
  font-weight: bolder;
  opacity: 0;
  transition: ease-in-out;
  transition-duration: 500ms;

}
.show-body + .collapsible-body {

  visibility: visible;
  opacity: 1;
  height: 100%;
  transition: ease-in-out;
  transition-duration: 500ms;

}
.down-icon {

  transition: ease-in-out;
  transition-duration: 500ms;

}
.show-body > .down-icon {

  transform: rotateX(180deg);
  transition: ease-in-out;
  transition-duration: 500ms;

}

/* PRODUCTS IMG */
.products-img {

  aspect-ratio: 4/5;
  width: 200px;

}