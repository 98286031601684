/* ABOUT US */
.bg-img-us {

    background-image: url('../assets/media/base/about-us/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}
.bg-cover-regions {

    background-image: url('../assets/media/base/about-us/cover.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}

/* ALLIATES */
#first {

    background-image: url('../assets/media/base/alliates/first.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}
#second {

    background-image: url('../assets/media/base/alliates/second.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}
#third {

    background-image: url('../assets/media/base/alliates/third.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}

/* SERVICES */
.bg-img-advisory {

    background-image: url('../assets/media/base/services/advisory.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;   

}
.bg-img-post {

    background-image: url('../assets/media/base/services/post.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;   

}
.bg-img-service {

    background-image: url('../assets/media/base/services/service.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;   

}

/* FAQ */

.bg-img-devices-faq {

    background-image: url('../assets/media/base/faq/bg-devices-faq.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}
.bg-img-devices-faq .collapsible-body {

    border-bottom: 2px solid white;

}
.bg-img-support-faq {

    background-image: url('../assets/media/base/faq/bg-support-faq.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--blue);

}
.bg-img-support-faq .collapsible-body {

    border-bottom: 2px solid var(--blue);

}

/* PRODUCTS */
.bg-tech {

    background-image: url('../assets/media/base/products/tech_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}
.bg-extra {

    background-image: url('../assets/media/base/products/extra_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

}