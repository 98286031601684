/* RADIUS */
.border-2 {

    border-width: 4px !important;
    border-style: solid;

}
.border-45 {

    border-radius: 45px;

}

.border-end-lg {

    border-right: 4px solid;

}

.border-gray {

    border-color: lightgray;

}