@font-face {
  font-family: 'Popins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: content-box ;
  font-family: 'Popins' !important;
  font-weight: 600;
  line-height: 33px !important;
}
body::-webkit-scrollbar {

  background-color: transparent;
  width: 10px;

}
body::-webkit-scrollbar-thumb {

  background-color: var(--red);

}

/* HEADER */
header {

  background-image: url('./assets/media/base/header-gif.gif');
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

}
header::after {

  content: "";
  background: rgb(25,70,129);
  background: linear-gradient(0deg, rgba(25,70,129,1) 0%, rgba(25,70,129,.6) 60%, rgba(25,70,129,.3) 100%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

}
header>div {

  display: flex;
  justify-content: center;
  margin-top: 5%;
  height: 75%;
  width: 100%;

}
header img {

  height: 60%;
  width: 60%;

}

/* NAV */
nav {

  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;

}
nav div {

  background-color: transparent;

}
.routes-div {

  justify-content: center;
  gap: 50px;

}
nav a {

  text-decoration: none;
  font-weight: bold;
  color: white;

}
nav[class*="nav-white"] .form-search-mobile input::placeholder, nav[class*="nav-white"] .form-search-mobile svg {

  color: black !important;

}
nav[class*="nav-white"] a, nav[class*="nav-white"] input::placeholder, nav[class*="nav-white"] input, nav[class*="nav-white"] svg {

  color: white !important;

}
nav[class*="gray-gradient"] a, nav[class*="gray-gradient"] input::placeholder, nav[class*="gray-gradient"] input, nav[class*="gray-gradient"] svg {

  color: black !important;

}

#mobileMenu a, #mobileMenu button svg {

  color: black !important;

}

/* Navbar Brand */
.navbar-brand {

  padding-left: 50px;

}
.navbar-brand img {

  width: 50%;

}

/* SEARCH INPUT */
.form-search input {

  border-width: 2px;
  border-style: solid;
  color: white;

}
.form-search input::placeholder {

  color: white;

}
.form-search .search-icon {

  right: 15px;
  top: 8%;
  border: none;
  background-color: transparent;

}

#mobileSearchBar {

  left: 0;
  top: 100%;

}
.form-search-mobile .search-icon {

  right: 15px;
  top: 7%;

}

/* CONTACT FORM */
.contact-form {

  background-image: url('./assets/media/base/form-bg.webp');
  background-size: cover;

}
.contact-form select, .contact-form input, .contact-form select, .contact-form input::placeholder, .contact-form label, .contact-form textarea::placeholder, .recurs-contact-form input, .recurs-contact-form textarea, .recurs-contact-form select, .recurs-contact-form input::placeholder, .recurs-contact-form label, .recurs-contact-form textarea::placeholder {

  color: white !important;
  /*font-size: .75rem;*/

}

/* RECURS FORM */
.recurs-contact-form {

  background-image: url('./assets/media/base/recurs/recurs-form.png');
  background-size: cover;

}

/* SELECTS */
select option {

  color: black !important;

}

/* PRODUCTS GRID */
.hoverable-product:hover {

  transform: scale(1.05);

}
.no-stock-brand {

  width: 120px;
  height: 120px;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 99;

}
.no-stock-brand>span {

  transform: rotate(-45deg);
  position: absolute;
  left: -35px;
  top: 35px;
  line-height: 25px;
  z-index: 2;
  background-color: var(--red);
  color: white;
  width: 180px;

}

/* WHATSAPP BUTTON */
.ws {

  text-align: center;
  background-color: #25d366;
  border: 2px solid;
  border-color: white;
  color: white;
  border-radius: 50%;
  font-size: 2rem;
  right: 3rem;
  bottom: 3rem;
  z-index: 9999;

}

/* PROMOTION */
.promo-img {

  top: 10%;
  left: 25%;
  height: 80%;
  width: 50%;

}
.bg-promo {

  background-color: rgba(0, 0, 0, .6);

}
.icon-promo {

  position: absolute;
  top: 10%;
  right: 25%;
  z-index: 9999999;
  color: white;
  background-color: transparent;
  border: none;

}

/* SVG */
svg {

  pointer-events: none;

}