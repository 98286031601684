.carousel {

    display: flex;
    width: 500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;

}
.carousel-top {

    width: 1200px;
    margin-top: 650px;

}
.carousel>#slider{

    display: flex;
    height: auto;
    overflow-x: hidden;
    width: 2000px;

    animation: slide 10000ms ease-in-out infinite;

}
.carousel>#objetivesSlider{

    display: flex;
    height: auto;
    overflow-x: hidden;
    width: 2000px;

    animation: objetivesSlider 10000ms ease-in-out infinite;

}
.carousel-top img {
    width: 400px !important;
    height: 400px;
    object-fit:contain;
    scroll-snap-type: center;
    text-align: center;
}
.carousel>#objetivesSlider img{

    width: 500px;
    object-fit:contain;
    scroll-snap-type: center;
    text-align: center;

}

/* ARROWS */
#left-arrow, #right-arrow {

    border: none;
    background-color: transparent;
    color: var(--blue);
    font-size: 3rem;
    top: calc(50% - 3rem);
    width: 3rem;

}
#left-arrow{

    left: 0;

}
#right-arrow {

    right: 0;

}
#left-arrow-clients, #right-arrow-clients {

    border: none;
    background-color: transparent;
    color: var(--blue);
    font-size: 3rem;
    top: calc(50% - 1rem);
    width: 3rem;

}
#left-arrow-clients {

    left: 3rem;

}
#right-arrow-clients {

    right: 3rem;

}

/* ANIMATION */
@keyframes objetivesSlider {
    
    0% {

        margin-left: 0%;

    }
    20% {

        margin-left: 0%;

    }
    25% {

        margin-left: -500px;

    }
    45% {

        margin-left: -500px;

    }
    50% {

        margin-left: -1000px;

    }
    70% {

        margin-left: -1000px;

    }
    75% {

        margin-left: -1500px;

    }
    95% {

        margin-left: -1500px;

    }
    100% {

        margin-left: 0px;

    }

}
@keyframes objetivesSlider-min {
    
    0% {

        margin-left: 0%;

    }
    20% {

        margin-left: 0%;

    }
    25% {

        margin-left: -350px;

    }
    45% {

        margin-left: -350px;

    }
    50% {

        margin-left: -700px;

    }
    70% {

        margin-left: -700px;

    }
    75% {

        margin-left: -1050px;

    }
    95% {

        margin-left: -1050px;

    }
    100% {

        margin-left: 0px;

    }

}
@keyframes slide {
    
    0% {

        margin-left: 0;

    }
    30% {

        margin-left: 0px;

    }
    33% {

        margin-left: -400px;

    }
    63% {

        margin-left: -400px;

    }
    66% {

        margin-left: -800px;

    }
    97% {

        margin-left: -800px;

    }
    100% {

        margin-left: 0px;

    }

}

@keyframes slide {
    
    0% {

        margin-left: 0;

    }
    30% {

        margin-left: 0px;

    }
    33% {

        margin-left: -400px;

    }
    63% {

        margin-left: -400px;

    }
    66% {

        margin-left: -800px;

    }
    97% {

        margin-left: -800px;

    }
    100% {

        margin-left: 0px;

    }

}

@keyframes slide-lg {
    
    0% {

        margin-left: 0;

    }
    30% {

        margin-left: 0px;

    }
    33% {

        margin-left: -300px;

    }
    63% {

        margin-left: -300px;

    }
    66% {

        margin-left: -600px;

    }
    97% {

        margin-left: -600px;

    }
    100% {

        margin-left: 0px;

    }

}

@keyframes slide-mid {

    0% {

        margin-left: 0px;

    }
    22% {

        margin-left: 0px;

    }
    25% {

        margin-left: -250px;

    }
    47% {

        margin-left: -250px;

    }
    50% {

        margin-left: -500px;

    }
    72% {

        margin-left: -500px;

    }
    75% {

        margin-left: -750px;

    }
    97% {

        margin-left: -750px;

    }
    100% {

        margin-left: 0px;

    }

}

@keyframes slide-min {

    0% {

        margin-left: 0px;

    }
    17% {

        margin-left: 0px;

    }
    20% {

        margin-left: -350px;

    }
    37% {

        margin-left: -350px;

    }
    40% {

        margin-left: -700px;

    }
    57% {

        margin-left: -700px;

    }
    60% {

        margin-left: -1050px;

    }
    77% {

        margin-left: -1050px;

    }
    80% {

        margin-left: -1400px;

    }
    97% {

        margin-left: -1400px;

    }
    100% {

        margin-left: 0px;

    }

}