/* NAV */
@media (max-width: 996px){

    nav {

        justify-content: space-between;
        padding: .5rem;

    }
    nav .routes-div {

        display: none !important;

    }
    nav .menu-button {

        display: flex !important;

    }
    nav img {

        width: 120px !important;

    }
    .navbar-brand {

        padding-left: 20px;

    }
    #mobileMenu {

        top: 0;
        right: 0;
        width: 40%;
        z-index: 9999;

    }
    #mobileMenu ul {

        padding: 1rem 1.5rem;

    }
    #mobileMenu a {

        color: black;

    }
    #mobileMenu button {

        position: absolute;
        font-size: 1.5rem;
        top: 1rem;
        right: 1rem;

    }

}
@media (max-width: 627px) {

    .search-min {

        display: flex !important;

    }
    .search-div, .form-search {

        display: none !important;

    }

}

/* HEADER */
@media (max-width: 768px){

    header > div {

        align-items: center;
        flex-direction: column-reverse;
        margin-top: 13%;

    }
    header img {

        width: 200px;

    }
    header > div div:last-of-type {

        margin: auto;

    }

    /* PROMOTION */
    .promo-img {

        top: 15%;
        left: 20%;
        height: 70%;
        width: 60%;
    
    }
    .icon-promo {

        right: 20%;
        top: 15%;

    }

}
@media (max-width: 575px) {

    header>div {
  
      margin-top: 30%;
  
    }
    header img{

        display: none;

    }

    /* PROMOTION */
    .promo-img {

        top: 15%;
        left: 10%;
        height: 70%;
        width: 80%;
    
    }
    .icon-promo {

        right: 10%;
        top: 15%;

    }
  
}
@media (max-width: 425px) {

    header > div {

        margin-top: 20%;

    }

    #mobileMenu {

        width: 60%;

    }

}
@media (max-width: 375px) {

    header > div {

        margin-top: 25%;

    }

}

/* INDEX PRODUCT CAROUSEL */
@media (max-width: 1250px) {

    .carousel-top {

        width: 900px;

    }
    .carousel-top>div {

        animation: slide-lg 10000ms ease-in-out infinite !important;
        width: 1500px;

    }
    .carousel-top img {

        width: 300px !important;
        height: 300px;

    }

}
@media (max-width: 940px) {

    .carousel {

        width: 500px;
    
    }
    .carousel-top>div {

        animation: slide-mid 10000ms ease-in-out infinite !important;
        width: 1250px;

    }
    .carousel-top img {

        width: 250px !important;
        height: 250px;

    }

}

@media (max-width: 540px) {

    .carousel {

        width: 350px;
    
    }
    .carousel-top>div {

        animation: slide-min 10000ms ease-in-out infinite !important;
        width: 1750px;

    }
    .carousel-top img {

        width: 350px !important;
        height: 350px;

    }
    .carousel > #objetivesSlider{

        animation: objetivesSlider-min 10000ms ease-in-out infinite !important;
        width: 1400px;

    }
    .carousel #objetivesSlider img {

        width: 350px;

    }

}

/* IMAGES */
/* INDEX CONTACT IMAGE */
@media (max-width: 540px) {

    .index-contact-form-img {

        width: 100% !important;

    }

}

/* FAQ */
@media (max-width: 996px) {

    .border-end-lg {

        border-right: none;
    
    }
    .faqs {

        padding-left: 0 !important;

    }

}